// This is the template for all the signs
import { graphql, Link } from "gatsby";
import * as React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../../components/layout";
import { undelineMD, undelineSM } from "../../utils/cssGroups";
import { Seo } from "../../components/seo";
// ------------> Events (plural) page <------------ //

const Page = (props) => {
  const events = props.data.allStrapiEvent.nodes;

  // getCategoriesFromAll signs and make them one array
  const allEventLocation = events.map((a) => a.eventLocation.country);
  let uniqueEventLocations = new Set(allEventLocation.flat());
  let eventLocations = Array.from(uniqueEventLocations);
  eventLocations = ["Global", ...new Set(eventLocations.flat())];

  // ------> Filtered data <------ //
  const [eventFilteredData, setEventFilteredData] = React.useState(events);
  // ------> Unique category selected <------ //
  const [selectedEventLocation, setSelectedEventLocation] = React.useState(
    eventLocations && eventLocations[0]
  );

  const handleEventLocations = (
    location,
    events,
    setEventFilteredData,
    setSelectedEventLocation
  ) => {
    if (location === "Global") {
      setEventFilteredData([...events]);
      setSelectedEventLocation(location);
    } else {
      setEventFilteredData([
        ...events.filter((edge) => {
          return edge.eventLocation.country.includes(location) && location;
        }),
      ]);
      setSelectedEventLocation(location);
    }
  };

  return (
    <Layout
      location={props.location}
      title={props.data.site.siteMetadata.title}
      bodyClass={"bg-amber-300"}
    >
      {/* ---> Countries List <--- */}
      <div className="mb-[2.6em] sticky top-[2.6em] lg:top-[3.9em] z-10">
        <div className="flex flex-wrap content-start">
          {eventLocations.map((location, index) => {
            return (
              <button
                key={index}
                onClick={() => {
                  handleEventLocations(
                    location,
                    events,
                    setEventFilteredData,
                    setSelectedEventLocation
                  );
                }}
                className={`text-md md:text-lg capitalize mr-10  ${
                  location === selectedEventLocation ? "decoration-white" : ""
                } ${undelineMD}`}
              >
                <span className=" mt-2 relative block max-w-full">
                  <span className="inline bg-amber-300 box-decoration-clone px-0">
                    {location}
                  </span>
                </span>
              </button>
            );
          })}
        </div>
      </div>
      {/* Text  */}

      <div className="text-md md:text-lg mb-[1.3em]">
        ‘Signs of Change’ is a global series of public installations,
        exhibitions and workshops. The project invites people to get inspired
        and take part in imagining future ways of living centered around extreme
        climate changes and the social, political and economical ramifications
        (amongst others) they might have.
      </div>
      {/* ---> Event Grid <--- */}
      <div className=" place-items-top text-center grid grid-cols-1 md:grid-cols-2 gap-[2.6em] mb-20">
        {eventFilteredData.map((event, index) => {
          return (
            <Link
              key={index}
              to={`/events/${event.slug}`}
              data-country={event.eventLocation.country}
              className={`group mb-[2.6rem] ${
                event.comingSoon ? "pointer-events-none" : "pointer-events-auto"
              }`}
            >
              {/* Image */}
              <div className={`mb-[1.3em]`}>
                {event.eventImages.map((images, index) => {
                  const eventHero = getImage(
                    images.localFile.childImageSharp.gatsbyImageData
                  );
                  return index === 0 ? (
                    <div className={`relative  `}>
                      <GatsbyImage
                        key={index}
                        image={eventHero}
                        alt=""
                        className={`relative  ${
                          event.comingSoon ? "opacity-50" : "opacity-100"
                        }`}
                        imgStyle=""
                      />
                      {event.comingSoon ? (
                        <div className="absolute w-full h-full grid place-content-center top-0">
                          <div className={`text-white opacity-100 `}>
                            <span className={`${undelineSM}`}>Coming soon</span>
                            ...
                          </div>
                        </div>
                      ) : null}
                    </div>
                  ) : null;
                })}
              </div>
              {/* Title */}
              <div
                className={`text-md text-left md:text-lg capitalize ${
                  event.comingSoon ? "opacity-50" : "opacity-100"
                }`}
              >
                {event.eventTitle}
              </div>
            </Link>
          );
        })}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query EventsPage {
    allStrapiEvent(sort: { fields: eventDate, order: DESC }) {
      nodes {
        addReactions {
          participantImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          participantName
          participantQuote
        }
        eventLocation {
          country
        }
        eventFeatured
        eventTitle
        eventType
        comingSoon
        slug
        eventImages {
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: DOMINANT_COLOR
                formats: NO_CHANGE
                #width: 700
                height: 400
                quality: 100
                #layout: FULL_WIDTH
                transformOptions: { fit: COVER }
                layout: CONSTRAINED
              )
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;

export default Page;

// *---> SEO
export const Head = () => (
  <Seo
    title={"Events"}
    description={
      "Specualtive Workshops and Events Exploring The Impact Of Extreme Climate Futures Through Signage Systems"
    }
    keywords={"specualtive design, art instalation"}
  />
);
